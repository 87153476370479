
import { Vue, Options } from 'vue-class-component';
import { LockOutlined, UserOutlined } from '@ant-design/icons-vue';
import router from '../router';

@Options({
    components: {
        'user-outlined': UserOutlined,
        'lock-outlined': LockOutlined
    }
})
export default class Redirect extends Vue {
    mounted() {
        // Get token from query parameters
        const queryParams = new URLSearchParams(window.location.search);
        const token = queryParams.get('token');

        if (token !== null && token !== undefined && token.length > 0) {
            // Store the token
            window.localStorage.setItem(process.env.VUE_APP_ACCESS_TOKEN_IDENTIFIER!, token);
            
            // Redirect to main page
            router.push({
                name: 'extension_table'
            });
        }
    }
}
